<template>
	<v-card flat class="mt-5">
		<!-- <v-form> -->
		<div class="px-3">
			<v-card-text class="pt-5">
				<v-row>
					<v-col cols="12" sm="8" md="6">
						<!-- current password -->
						<v-text-field
							v-model="OldPassword"
							:type="isOldPasswordVisible ? 'text' : 'password'"
							:append-icon="isOldPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
							label="Current Password"
							outlined
							dense
							@click:append="isOldPasswordVisible = !isOldPasswordVisible"
						></v-text-field>

						<!-- new password -->
						<v-text-field
							v-model="NewPassword"
							:type="isNewPasswordVisible ? 'text' : 'password'"
							:append-icon="isNewPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
							label="New Password"
							outlined
							dense
							hint="Make sure it's at least 8 characters."
							persistent-hint
							@click:append="isNewPasswordVisible = !isNewPasswordVisible"
						></v-text-field>

						<!-- confirm password -->
						<v-text-field
							v-model="ConfirmNewPassword"
							:type="isConfirmNewPasswordVisible ? 'text' : 'password'"
							:append-icon="isConfirmNewPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
							label="Confirm New Password"
							outlined
							dense
							class="mt-3"
							@click:append="isConfirmNewPasswordVisible = !isConfirmNewPasswordVisible"
						></v-text-field>
					</v-col>

					<v-col cols="12" sm="4" md="6" class="d-none d-sm-flex justify-center position-relative">
						<v-img
							contain
							max-width="170"
							src="@/assets/images/3d-characters/pose-m-1.png"
							class="security-character"
						></v-img>
					</v-col>
				</v-row>
			</v-card-text>
		</div>

		<!-- divider -->
		<v-divider></v-divider>

		<div class="pa-3">
			<!-- <v-card-title class="flex-nowrap">
          <v-icon class="text--primary me-3">
            {{ icons.mdiKeyOutline }}
          </v-icon>
          <span class="text-break">Two-factor authentication</span>
        </v-card-title>

        <v-card-text class="two-factor-auth text-center mx-auto">
          <v-avatar
            color="primary"
            class="primary mb-4"
            rounded
          >
            <v-icon
              size="25"
              color="white"
            >
              {{ icons.mdiLockClock }}
            </v-icon>
          </v-avatar>
          <p class="text-base text--primary font-weight-semibold">
            Two factor authentication is not enabled yet.
          </p>
          <p class="text-sm text--primary">
            Two-factor authentication adds an additional layer of
            security to your account by requiring more than just a
            password to log in. Learn more.
          </p>
        </v-card-text> -->

			<!-- action buttons -->
			<v-card-text>
				<v-btn color="primary" class="me-3 mt-3" :loading="loading" v-on:click="saveNewPassword" :disabled="loading">
					Save changes
				</v-btn>
				<v-btn color="secondary" outlined class="mt-3"> Cancel </v-btn>
			</v-card-text>
		</div>
		<!-- </v-form> -->
	</v-card>
</template>

<script>
	// eslint-disable-next-line object-curly-newline
	import { mdiKeyOutline, mdiLockClock, mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
	import { ref } from '@vue/composition-api'
	import Util from '@/utils/axios-util.js'

	export default {
		setup() {
			const isOldPasswordVisible = ref(false)
			const isNewPasswordVisible = ref(false)
			const isConfirmNewPasswordVisible = ref(false)
			const OldPassword = ref('')
			const NewPassword = ref('')
			const ConfirmNewPassword = ref('')
			const loading = ref(false)

			return {
				isOldPasswordVisible,
				isNewPasswordVisible,
				OldPassword,
				isConfirmNewPasswordVisible,
				NewPassword,
				ConfirmNewPassword,
				icons: {
					mdiKeyOutline,
					mdiLockClock,
					mdiEyeOffOutline,
					mdiEyeOutline,
				},
				loading,
			}
		},
		methods: {
			saveNewPassword: function () {
				let e = this
				if (!e.OldPassword) {
					e.errorMessage('Please enter current password.')
					return false
				}
				if (e.OldPassword.length < 8) {
					e.errorMessage('Current password should have minimum 8 charactors.')
					return false
				}
				if (!e.NewPassword) {
					e.errorMessage('Please enter new password.')
					return false
				}
				if (e.NewPassword.length < 8) {
					e.errorMessage('New password should have minimum 8 charactors.')
					return false
				}
				if (!e.ConfirmNewPassword) {
					e.errorMessage('Please enter confirm password.')
					return false
				}
				if (e.ConfirmNewPassword.length < 8) {
					e.errorMessage('Confirm password should have minimum 8 charactors.')
					return false
				}
				if (e.NewPassword == e.OldPassword) {
					e.errorMessage('New and current password should not be same.')
					return false
				}
				if (e.NewPassword !== e.ConfirmNewPassword) {
					e.errorMessage('New and confirm password should not be diffrent.')
					return false
				}
				e.loading = true
				Util.Axios.post('/home/change-password', {
					OldPassword: e.OldPassword,
					NewPassword: e.NewPassword,
					ConfirmNewPassword: e.ConfirmNewPassword,
				})
					.then(function (response) {
						if (response.data.succeeded) {
							e.successMessage('Password changed successfully.')
							e.$router.push({ name: 'login' })
						} else {
							e.errorMessage(e.generateErrorMessage(response.errors))
						}
					})
					.catch(function (response) {
						e.errorMessage(response)
					})
					.finally(function () {
						e.loading = false
					})
			},
		},
	}
</script>

<style lang="scss" scoped>
	.two-factor-auth {
		max-width: 25rem;
	}
	.security-character {
		position: absolute;
		bottom: -0.5rem;
	}
</style>
